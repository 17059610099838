<template>
  <div :class="`w-full aspect-[1500/656] overflow-hidden relative h-auto block bg-gray-200 dark:bg-zinc-800`">
    <img v-if="src" :src="src" class="w-full h-auto" @load="hideBlurhashOnImageLoad" lazy ref="rootImage" :alt="alt" width="600" height="262" />
    <transition
      enter-active-class="duration-500 ease-out transition"
      enter-from-class="transform opacity-0 transition"
      enter-to-class="opacity-1000"
      leave-active-class="duration-250 ease-in transition"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0 transition"
      >
        <blurhash-canvas
          v-if="(!visible || isLoading) && hash && hash.length > 0"
          :hash="hash" :punch="punch" :height="resolutionY" :width="resolutionX">
        </blurhash-canvas>
    </transition>
  </div>
</template>

<script setup>
import BlurhashCanvas from './BlurhashCanvas.vue'
import { ref, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  hash: {
    type: String,
    required: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  punch: {
    type: Number,
    default: 1
  },
  resolutionY: {
    type: Number,
    default: 16
  },
  resolutionX: {
    type: Number,
    default: 16
  },
  src: {
    type: String,
    required: false,
  },
  alt: {
    type: String,
    required: false,
  }
})

const emit = defineEmits(['imageLoaded'])

let visible = ref(false)
let rootImage = ref(null) // <HTMLImageElement | null>

// let blurhashContainer = ref()
let containerHeight = ref()
// ex: 1500x656
const ratio = 1500 / 656

const parseResize = () => {
  // containerHeight.value = blurhashContainer.value.offsetWidth / ratio;
}

onMounted(() => {
  // containerHeight.value = blurhashContainer.value.offsetWidth / ratio;
  // window.addEventListener('resize', parseResize)

  if (rootImage.value?.complete) {
    hideBlurhashOnImageLoad()
    // console.log('aqui =>', blurhashContainer.value.offsetWidth, ratio)
    // containerHeight.value = blurhashContainer.value.offsetWidth / ratio;
  }


  // console.log(blurhashContainer.value.offsetWidth)
})

onBeforeUnmount(() => {
  // window.removeEventListener('resize', parseResize)
})

watch(() => props.src, () => {
  visible.value = false
})

let hideBlurhashOnImageLoad = () => {
  // console.log('aqui =>', blurhashContainer.value.offsetWidth, ratio)
  // console.log('hideBlur')
  // parseResize()
  visible.value = true
  emit('imageLoaded')
}
</script>
